<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#9de35c"
      loader="bars"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-col
      class="align-bottom text-right px-0"
      style="margin-bottom: -6px;"
    >
      <v-switch
        v-model="newDevicesSwitch"
        style="position:absolute;width: 180px"
        hide-details
        label="Show new devices"
        class="pa-0 ma-0"
      />
      <app-btn
        v-if="
          workingActions &&
            workingActions.length > 0 &&
            selectedDevices &&
            selectedDevices.length > 0
        "
        class="mx-2"
        @click="saveChanges()"
      >
        <v-icon>mdi-view-grid-plus</v-icon> Save changes
      </app-btn>
      <div
        v-if="selectedDevices && selectedDevices.length"
        style="display: inline; margin-right: 5px"
      >
        <!-- <app-btn
          @click="()=> dialogConfigurations = true"
        >
          <v-icon class="mr-1">
            mdi-view-grid-plus
          </v-icon> Add Configurations
        </app-btn> -->
      </div>
      <app-btn
        class="mr-1"
        @click="dialogCreateDevice.show(undefined, { formTitle: 'Add New Device' })"
      >
        <v-icon class="mr-1">
          mdi-view-grid-plus
        </v-icon> Add New Device
      </app-btn>
      <app-btn
        class="mr-2"
        @click="() => {
          if (!this.selectedDevices.length) {
            this.$toast.error('No device selected', 'Error', { position: 'topRight', })
            return
          };

          dialogXmlCommand.show(undefined, {
            formTitle: 'Send XML Command'
          })
        }"
      >
        <v-icon class="mr-1">
          mdi-send-variant
        </v-icon> Send Xml Command
      </app-btn>
      <app-btn @click="generateReport">
        <v-icon class="mr-1">
          mdi-view-grid-plus
        </v-icon> Generate Report
      </app-btn>
      <!-- <app-btn class="mx-2" @click="dialogAppList = true">
        <v-icon>mdi-view-grid-plus</v-icon> App list
      </app-btn> -->
    </v-col>
    <material-card
      icon="mdi-cellphone-cog"
      icon-small
      color="primary"
      :title="selectedCompanyName + ' Devices'"
    >
      <v-card-text>
        <v-row>
          <!-- <v-col class="text-left">
            <v-switch v-model="singleExpand" label="Single expand" />
          </v-col> -->
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="ml-auto"
              hide-details
              label="Search records"
              single-line
              style="max-width: 250px"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3" />
        <v-data-table
          v-model="selectedDevices"
          :headers="headers"
          :items="mobileStatuses"
          :options.sync="options"
          :search.sync="search"
          :server-items-length="totalDevices"
          :loading="loading"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id"
          show-select
          :show-expand="isExpand"
          :items-per-page="5"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
          @item-expanded="(data) => onExpanded(data)"
        >
          <template v-slot:top>
            <v-dialog
              v-model="dialogConfigurations"
              max-width="1350px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">Add Configurations</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <div
                      class="d-flex align-center justify-center ma-1 mb-1"
                      style="padding: 15px 20px; border-radius: 5px; background: #f5f5f5;"
                    >
                      <v-autocomplete
                        v-model="referenceId"
                        label="Reference"
                        :items="companies"
                        item-text="pathStr"
                        item-value="id"
                        hide-details
                      />
                      <app-btn
                        class="mt-1 ml-5"
                        @click="chooseReference"
                      >
                        Choose
                      </app-btn>
                    </div>
                    <multi-config-form v-model="editedItems" />
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeConfigurations"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="addConfigurations"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <app-list
              :dialog="dialogAppList"
              @close="dialogAppList = false"
            />
            <device-files-dialog
              :dialog="deviceFilesDialog"
              :device-id="currentDeviceId"
              @actions="(actions) => (workingActions = actions)"
              @close="deviceFilesDialog = false"
            />
            <device-messages-dialog
              v-model="deviceMessagesDialog"
              :device-id="currentDeviceId"
            />
            <v-dialog
              v-model="dialogDeleteLocalData"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="text-h5">
                  Delete Local Records
                </v-card-title>
                <v-card-text align="center">
                  <app-btn
                    class="ma-2"
                    @click="deleteFailedPickingProductsFromLocalDb()"
                  >
                    <v-icon>mdi-delete</v-icon> Delete picking failed Products
                  </app-btn>
                  <app-btn
                    class="mx-2"
                    @click="deleteOrderProductsFromLocalDb()"
                  >
                    <v-icon>mdi-delete</v-icon> Delete order products & Reset
                    Login
                  </app-btn>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogDeleteLocalData = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogMessages"
              max-width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">Messages</span>
                </v-card-title>
                <v-card-text>
                  <v-row class="mx-2 mt-2">
                    <v-col md="10">
                      <v-text-field
                        v-model="message"
                        label="Message"
                      />
                    </v-col>
                    <v-col
                      md="2"
                      class="mt-2"
                    >
                      <v-btn
                        color="success"
                        @click="addMessage"
                      >
                        {{ btn }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-card
                    v-for="message in messages"
                    :key="message.id"
                    color="#E8F5E9"
                    class="ma-1 pa-1"
                  >
                    <v-row class="ma-1">
                      <v-col md="10">
                        {{ message.description }}
                      </v-col>
                      <v-col md="2">
                        <v-icon
                          small
                          color="info"
                          class="mr-2"
                          @click="editMessage(message)"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          color="error"
                          @click="deleteMessage(message)"
                        >
                          mdi-delete
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogMessages = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogRequiredVersion"
              max-width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">Required Version</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-autocomplete
                        v-model="currentRequiredVersion"
                        :items="appVersions"
                        item-text="fileName"
                        item-value="fileName"
                        label="Required Version"
                      />
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogRequiredVersion = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="updateRequiredVersion"
                  >
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogFtpProfile"
              max-width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">FTP Profile</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-autocomplete
                        v-model="currentFtpProfile"
                        :items="ftpProfiles"
                        item-text="name"
                        item-value="id"
                        label="FTP Profile"
                      />
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogFtpProfile = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="updateFtpProfile"
                  >
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogCurrentServer"
              max-width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">Current Server</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-autocomplete
                        v-model="currentServer"
                        :items="serverUrls"
                        item-text="value"
                        item-value="id"
                        label="Current Server Url"
                      />
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogCurrentServer = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="updateServerUrl"
                  >
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogBulkDeviceUpdate"
              max-width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">Device Settings</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="currentRequiredVersion"
                          :items="appVersions"
                          item-text="fileName"
                          item-value="fileName"
                          label="Required Version"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="currentFtpProfile"
                          :items="ftpProfiles"
                          item-text="name"
                          item-value="id"
                          label="FTP Profile"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="currentServer"
                          :items="serverUrls"
                          item-text="value"
                          item-value="id"
                          label="Current Server Url"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-row>
                          <div class="ml-2 mt-2">
                            Require Download:
                          </div>
                          <v-icon
                            v-if="currentRequiredDownload"
                            color="success"
                            large
                            @click="isRequiredDownload"
                          >
                            mdi-check-circle
                          </v-icon>
                          <v-icon
                            v-else
                            color="error"
                            large
                            @click="isRequiredDownload"
                          >
                            mdi-close-circle
                          </v-icon>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogBulkDeviceUpdate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="bulkDeviceUpdate"
                  >
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <form-dialog
              v-model="dialogReboot"
              :default-value="{ seconds: 0 }"
              @save="sendReboot"
            >
              <template v-slot:[`form`]="{ item }">
                <v-col cols="12">
                  <v-text-field
                    v-model="item.seconds"
                    type="number"
                    label="Timer in seconds"
                  />
                </v-col>
              </template>
            </form-dialog>
            <form-dialog
              v-model="dialogTelnetServerDetails"
              :default-value="{ serverName: '', host: '', port: 23 }"
              @save="updateTelnetServerDetails"
            >
              <template v-slot:[`form`]="{ item, options }">
                <v-col cols="12">
                  <v-text-field
                    v-model="item.serverName"
                    label="Server Name"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.host"
                    label="Server IP"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.port"
                    type="number"
                    label="Server Port"
                  />
                </v-col>

                <div v-if="options.item && options.item.telnet && options.item.telnet.token">
                  <q-r-code
                    :value="options.item.telnet.token"
                    :size="200"
                  />
                </div>
              </template>
            </form-dialog>
            <form-dialog
              v-model="dialogCreateDevice"
              :default-value="{ deviceId: '' }"
              @save="addNewDevice"
            >
              <template v-slot:[`form`]="{ item }">
                <v-col cols="12">
                  <v-text-field
                    v-model="item.deviceId"
                    label="Device Id"
                  />
                </v-col>
              </template>
            </form-dialog>
            <form-dialog
              v-model="dialogXmlCommand"
              :default-value="{ info: '' }"
              @save="sendXmlCommand"
            >
              <template v-slot:[`form`]="{ item }">
                <v-col cols="12">
                  <v-textarea
                    v-model="item.info"
                    label="XML Command"
                  />
                </v-col>
              </template>
            </form-dialog>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <v-edit-dialog
              large
              @save="updateDeviceName(item)"
            >
              <u class="primary--text">{{ item.name }}</u>
              <template v-slot:input>
                <v-text-field
                  v-model="item.name"
                  label="Name"
                />
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.company`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ getCompany(item.company) }}
                </div>
              </template>
              <span>{{ item.company }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.invoiceNumber`]="{ item }">
            <v-edit-dialog
              large
              @save="updateDeviceName(item)"
            >
              <u class="primary--text">{{ item.invoiceNumber }}</u>
              <template v-slot:input>
                <v-text-field
                  v-model="item.invoiceNumber"
                  label="Invoice Number"
                />
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:[`item.statusId`]="{ item }">
            <v-edit-dialog
              large
              @save="updateDeviceName(item)"
            >
              <u class="primary--text">{{ item.name }}</u>
              <template v-slot:input>
                <v-text-field
                  v-model="item.name"
                  label="Invoice Number"
                />
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <draggable
              class="list-group"
              tag="div"
              :list="[item.id]"
              group="devices"
              @change="dragEvent($event)"
            >
              <device-icon :device-model="item.deviceModel" />
            </draggable>
          </template>
          <template v-slot:[`item.network`]="{ item }">
            <div><span style="font-weight: 600;">IP: </span>{{ item.ipAddress }}</div>
            <div><span style="font-weight: 600;">SIGNAL: </span>{{ item.signalStrength }}</div>
            <div><span style="font-weight: 600;">AP: </span>{{ item.accessPointId }}</div>
            <div><span style="font-weight: 600;">SPEED: </span>{{ item.linkSpeed }}</div>
            <div><span style="font-weight: 600;">ESSID: </span>{{ item.essid }}</div>
          </template>
          <template v-slot:[`item.data`]="{ item }">
            <div style="display: flex; flex-direction: column; gap: 10px; cursor: pointer;">
              <u
                class="primary--text"
                @click="
                  deviceMessagesDialog = true;
                  currentDeviceId = item.id;
                "
              >Messages
              </u>
              <div>
                <v-edit-dialog
                  large
                  @save="updateDeviceName(item)"
                >
                  <u class="primary--text">Notes</u>
                  <template v-slot:input>
                    <v-textarea
                      v-model="item.notes"
                      label="Notes"
                    />
                  </template>
                </v-edit-dialog>
              </div>
              <u
                class="primary--text"
                @click="
                  deviceFilesDialog = true;
                  currentDeviceId = item.id;
                "
              >Files</u>
              <u
                class="primary--text"
                @click="() => $router.push({ name: 'Remote Assistance', params: { id: item.macAddress } })"
              >Remote
                Assistance</u>
            </div>
          </template>
          <!-- <template v-slot:[`item.messages`]="{ item }">
            <v-chip
              class="ma-2"
              text-color="white"
              color="success"
              @click="openMessageDialog(item.id, item.messages)"
            >
              {{ item.messages.length }}
            </v-chip>
          </template> -->
          <template v-slot:[`item.deleteLocalData`]="{ item }">
            <v-icon
              color="error"
              @click="openDialogDeleteLocalData(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:[`item.requiredVersion`]="{ item }">
            <v-chip
              color="success"
              dark
              @click="openDialogRequiredVersion(item)"
            >
              {{ item.requiredVersion }}
            </v-chip>
          </template>
          <template v-slot:[`item.requireDownload`]="{ item }">
            <v-icon
              v-if="item.requireDownload"
              color="success"
              large
              @click="updateRequireDownload(item, false)"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-else
              color="error"
              large
              @click="updateRequireDownload(item, true)"
            >
              mdi-close-circle
            </v-icon>
          </template>
          <template v-slot:[`item.ftpConfiguration`]="{ item }">
            <v-chip
              color="success"
              dark
              @click="openDialogFtpProfile(item)"
            >
              {{ item.ftpConfiguration ? item.ftpConfiguration.name : "" }}
            </v-chip>
          </template>
          <template v-slot:[`item.currentServer`]="{ item }">
            <v-chip
              color="success"
              dark
              @click="openDialogCurrentServer(item)"
            >
              {{ item.currentServer ? item.currentServer.name : "" }}
            </v-chip>
          </template>
          <template v-slot:[`item.lastConnected`]="{ item }">
            <div>{{ timeFormat(item.lastConnected) }}</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              medium
              color="info"
              @click="showTelnetServerDetails(item)"
            >
              mdi-console
            </v-icon>
            <v-icon
              medium
              color="info"
              @click="dialogReboot.show(undefined, { formTitle: 'Send Reboot Command', item })"
            >
              mdi-restart
            </v-icon>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-4"
            >
              <v-tabs color="accent">
                <v-tab> Status </v-tab>
                <v-tab> License </v-tab>
                <v-tab> Location </v-tab>
                <v-tab> Installed apps </v-tab>
                <v-tab> Configs </v-tab>
                <v-tab> Wifi Configs </v-tab>
                <v-tab> Events </v-tab>
                <v-tab> Status Logs </v-tab>
                <v-tab-item>
                  <device-statuses :current-mobile-status="currentMobileStatus" />
                </v-tab-item>
                <v-tab-item>
                  <device-licenses :current-mobile-status="currentMobileStatus" />
                </v-tab-item>
                <v-tab-item>
                  <device-locations :current-mobile-status="currentMobileStatus" />
                </v-tab-item>
                <v-tab-item>
                  <device-apps :current-mobile-status="currentMobileStatus" />
                </v-tab-item>
                <v-tab-item>
                  <device-configs :device-id="currentMobileStatus" />
                </v-tab-item>
                <v-tab-item>
                  <wifi-configs
                    :type="2"
                    :reference-id="item.id"
                  />
                </v-tab-item>
                <v-tab-item>
                  <signal-events :reference-id="item.id" />
                </v-tab-item>
                <v-tab-item>
                  <status-logs :reference-id="item.id" />
                </v-tab-item>
              </v-tabs>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </div>
</template>
<script>
  import { get } from 'vuex-pathify'
  import SettingsDataService from '../services/SettingsDataService'
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'
  import DeviceStatuses from './components/DeviceStatuses.vue'
  import DeviceLicenses from './components/DeviceLicenses.vue'
  import DeviceApps from './components/DevicesApps.vue'
  import DeviceLocations from './components/DeviceLocations.vue'
  import DeviceConfigs from './components/DeviceConfigs.vue'
  import DeviceFilesDialog from './components/DeviceFilesDialog.vue'
  import DeviceMessagesDialog from './components/DeviceMessagesDialog.vue'
  import AppList from './components/AppsList.vue'
  import draggable from 'vuedraggable'
  import { parse } from 'json2csv'
  import DeviceIcon from './components/DeviceIcon.vue'
  import MultiConfigForm from './components/config/MultiConfigForm.vue'
  import RemoteAssistanceDialog from './components/RemoteAssistanceDialog.vue'
  import store from '@/store'
  import WifiConfigs from './WifiConfigs.vue'
  import SignalEvents from './SignalEvents.vue'
  import StatusLogs from './StatusLogs.vue'
  import QRCode from './components/QRCode.vue'

  var moment = require('moment')

  export default {
    name: 'Settings',
    components: {
      QRCode,
      WifiConfigs,
      RemoteAssistanceDialog,
      MultiConfigForm,
      DeviceIcon,
      Loading,
      SignalEvents,
      StatusLogs,
      DeviceStatuses,
      DeviceLicenses,
      DeviceApps,
      DeviceLocations,
      DeviceConfigs,
      DeviceFilesDialog,
      DeviceMessagesDialog,
      AppList,
      draggable,
    },
    data: () => ({
      moment: moment,
      currentMacAddress: '',
      currentRequiredVersion: '',
      currentFtpProfile: '',
      dialogDeleteLocalData: false,
      currentUserName: '',
      dialogFtpProfile: false,
      dialogConfigurations: false,
      referenceId: undefined,
      editedItems: [],
      dialogRequiredVersion: false,
      dialogCurrentServer: false,
      dialogBulkDeviceUpdate: false,
      deviceFilesDialog: false,
      deviceMessagesDialog: false,
      dialogReboot: null,
      dialogCreateDevice: null,
      dialogTelnetServerDetails: null,
      dialogXmlCommand: null,
      dialogAppList: false,
      sortBy: 'lastConnected',
      locationSortBy: 'time',
      isLoading: false,
      currentDeviceId: 0,
      options: {},
      totalDevices: 0,
      loading: false,
      loadingSize: 128,
      loadingFullPage: false,
      sortDesc: true,
      appSearch: '',
      selectedDevices: [],
      headers: [
        {
          text: 'Image',
          sortable: false,
          value: 'image',
        },
        { text: 'Company', sortable: false, value: 'company' },
        {
          text: 'Name',
          sortable: false,
          value: 'name',
        },
        { text: 'Data', sortable: false, value: 'data' },
        { text: 'Last Connected', sortable: false, value: 'lastConnected' },
        { text: 'Device Id', sortable: false, value: 'macAddress' },
        { text: 'OS', sortable: false, value: 'os' },
        { text: 'App Version', sortable: false, value: 'appVersion' },
        // {
        //   text: "Required App Version",
        //   sortable: false,
        //   value: "requiredVersion",
        // },
        // { text: "Require Update", sortable: false, value: "requireDownload" },
        // { text: "FTP Profile", sortable: false, value: "ftpConfiguration" },
        // { text: "Current Server", sortable: false, value: "currentServer" },
        // { text: "User", sortable: false, value: "username" },
        { text: 'Network', sortable: false, value: 'network' },
        { text: 'Invoice', sortable: false, value: 'invoiceNumber' },
        { text: 'Status', sortable: false, value: 'status' },
        { text: 'Actions', align: 'end', value: 'actions' },

      // { text: "Delete LocalData", sortable: false, value: "deleteLocalData" },
      ],
      isExpand: true,
      expanded: [],
      appVersions: [],
      ftpProfiles: [],
      serverUrls: [],
      singleExpand: true,
      dialogMessages: false,
      btn: '',
      mobileStatuses: [],
      currentMobileStatus: 0,
      messages: [],
      message: '',
      currentMessage: '',
      currentDevice: '',
      currentServer: '',
      currentRequiredDownload: false,
      search: '',
      searchTimer: null,
      newDevicesSwitch: false,
      workingActions: [],
      companyTimer: null,
    }),
    watch: {
      options: {
        handler () {
          // this.getDataFromApi(this.search);
          this.getDevicesByCompany()
        },
        deep: true,
      },
      search: {
        handler (val) {
          clearTimeout(this.searchTimer)
          this.searchTimer = setTimeout(() => {
            // this.getDataFromApi(this.search);
            this.getDevicesByCompany(val)
          })
        },
      },

      selectedCompanyId: {
        handler (val) {
          clearTimeout(this.companyTimer)
          this.companyTimer = setTimeout(() => {
            this.getDevicesByCompany()
          }, 2500)
        },
      },
      newDevicesSwitch (val) {
        if (val) {
          this.getNewDevices()
        } else {
          this.getDevicesByCompany()
        }
      },
    },
    computed: {
      ...get('company', ['selectedCompanyId', 'selectedCompanyName']),
      companies () {
        return store.getters['company/getCompanies']
      },
    },
    created () {
      this.initialize()
    },

    methods: {
      dragEvent (evt) {
        if (evt.hasOwnProperty('removed')) {
          this.mobileStatuses.splice(evt.removed.oldIndex, 1)
        }
      },
      onExpanded (data) {
        if (data.value) {
          this.currentMobileStatus = data.item.id
        }
      },
      getDataFromApi (search) {
        this.loading = true
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        var data = {
          page: page,
          limit: itemsPerPage,
        }
        this.$http('Device/GetAllDevices', { params: data })
          .then((response) => {
            this.totalDevices = response.data.total
            this.mobileStatuses = response.data.data
            this.loading = false
          })
          .catch(() => {
            this.mobileStatuses = []
            this.loading = false
          })
      },
      initialize () {
        SettingsDataService.getAppVersions().then((response) => {
          this.appVersions = response.data
        })
        SettingsDataService.getAllFtpConfig().then((response) => {
          this.ftpProfiles = response.data
        })
        SettingsDataService.getServerUrls().then((response) => {
          this.serverUrls = response.data
        })
      },
      getNewDevices () {
        this.loading = true
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        var data = {
          page: page,
          limit: itemsPerPage,
        }

        this.$http
          .get('Company/GetNewDevices', {
            params: data,
          })
          .then((response) => {
            this.totalDevices = response.data.total
            this.mobileStatuses = response.data.data
            this.loading = false
          })
          .catch((e) => {
            this.totalDevices = 0
            this.mobileStatuses = []
            this.loading = false
          })
      },
      getDevicesByCompany (search) {
        this.loading = true
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        var data = {
          ...(search && { search }),
          companyId: this.selectedCompanyId,
          page: page,
          limit: itemsPerPage,
        }

        this.$http
          .get('Company/DevicePaginationByCompany', {
            params: data,
          })
          .then((response) => {
            this.totalDevices = 100
            this.mobileStatuses = response.data
            this.loading = false
          })
          .catch((e) => {
            this.totalDevices = 0
            this.mobileStatuses = []
            this.loading = false
          })
      },
      openMessageDialog (id, messages) {
        this.dialogMessages = true
        this.btn = 'Add'
        this.message = ''
        this.messages = messages
        this.currentDevice = id
      },
      editMessage (message) {
        this.btn = 'Edit'
        this.message = message.description
        this.currentMessage = message.id
      },
      addMessage () {
        if (this.btn === 'Edit') {
          var data = {
            id: this.currentMessage,
            mobileStatusId: this.currentDevice,
            description: this.message,
            active: 1,
          }
          SettingsDataService.update(data).then((response) => {
            var message = this.mobileStatuses.find(
              (x) => x.id === this.currentDevice,
            ).messages
            var index = message.findIndex((x) => x.id === this.currentMessage)
            this.messages.splice(index, 1, data)
          })
          this.btn = 'Add'
        } else {
          var data = {
            mobileStatusId: this.currentDevice,
            description: this.message,
            active: 1,
          }
          SettingsDataService.create(data).then((response) => {
            this.mobileStatuses
              .find((x) => x.id == this.currentDevice)
              .messages.push(response.data)
          })
        }
        this.message = ''
      },
      deleteMessage (message) {
        SettingsDataService.delete(message.id).then((response) => {
          var message = this.mobileStatuses.find(
            (x) => x.id === this.currentDevice,
          ).messages
          var index = message.findIndex((x) => x.id === this.currentMessage)
          this.messages.splice(index, 1)
        })
      },
      getLicence (id) {
        var device = this.mobileStatuses.find((x) => x.id === id)
        if (device) {
          return device.licenses
        }
      },
      timeFormat (time) {
        var dateTime = new Date(time)
        return this.moment(dateTime).add(10, 'h').format('DD.MM.YYYY h:mm:ss a')
      },
      openDialogRequiredVersion (item) {
        this.currentRequiredVersion = ''
        this.currentMacAddress = item.macAddress
        this.dialogRequiredVersion = true
      },
      openDialogFtpProfile (item) {
        this.currentMacAddress = item.macAddress
        this.dialogFtpProfile = true
      },
      openDialogCurrentServer (item) {
        this.currentMacAddress = item.macAddress
        this.dialogCurrentServer = true
      },
      updateDeviceName (item) {
        this.$http
          .patch('MobileStatus/UpdateMobileStatus', {
            Id: item.id,
            Name: item.name,
            Notes: item.notes,
            InvoiceNumber: item.invoiceNumber,
            StatusId: item.statusId,
          })
          .then((response) => {
            if (response.data.success) {
              this.$toast.success(response.data.message, 'Success', {
                position: 'topRight',
              })
            } else {
              this.$toast.error(response.data.message, 'Error', {
                position: 'topRight',
              })
            }
          })
      },
      updateRequiredVersion () {
        var data = {
          macAddress: this.currentMacAddress,
          requiredVersion: this.currentRequiredVersion,
        }
        SettingsDataService.updateRequiredVersion(data).then((response) => {
          if (response.data.isSuccess) {
            const index = this.mobileStatuses.findIndex(
              (x) => x.macAddress == this.currentMacAddress,
            )
            if (index > -1) {
              const device = this.mobileStatuses[index]
              device.requiredVersion = this.currentRequiredVersion
              this.$set(this.mobileStatuses, index, device)
            }
            this.$toast.success(
              'Required status updated successfully.',
              'Success',
              {
                position: 'topRight',
              },
            )
          }
        })
        this.dialogRequiredVersion = false
      },
      updateRequireDownload (item, isRequired) {
        var data = {
          macAddress: item.macAddress,
          requireDownload: isRequired,
        }
        SettingsDataService.updateRequiredDownload(data).then((response) => {
          if (response.data.isSuccess) {
            const index = this.mobileStatuses.findIndex(
              (x) => x.macAddress == item.macAddress,
            )
            if (index > -1) {
              const device = this.mobileStatuses[index]
              device.requireDownload = isRequired
              this.$set(this.mobileStatuses, index, device)
            }
            this.$toast.success(
              'Required status updated successfully.',
              'Success',
              {
                position: 'topRight',
              },
            )
          }
        })
      },
      updateFtpProfile () {
        var data = {
          macAddress: this.currentMacAddress,
          ftpConfigurationId: this.currentFtpProfile,
        }
        SettingsDataService.updateDeviceFtpConfigurations(data).then(
          (response) => {
            if (response.data.isSuccess) {
              this.$toast.success(
                'Required status updated successfully.',
                'Success',
                {
                  position: 'topRight',
                },
              )
              this.getDataFromApi()
            }
          },
        )
        this.dialogFtpProfile = false
      },
      updateServerUrl () {
        var data = {
          macAddress: this.currentMacAddress,
          settingId: this.currentServer,
        }
        SettingsDataService.updateCurrentServerUrl(data).then((response) => {
          if (response.data.isSuccess) {
            this.$toast.success('Server url updated successfully.', 'Success', {
              position: 'topRight',
            })
            this.getDataFromApi()
          }
          this.dialogCurrentServer = false
        })
      },
      isRequiredDownload () {
        this.currentRequiredDownload = !this.currentRequiredDownload
      },
      bulkDeviceUpdate () {
        var macAddressList = []
        this.selectedDevices.forEach((x) => {
          macAddressList.push(x.macAddress)
        })
        var data = {
          macAddresses: macAddressList,
          settingId: this.currentServer,
          ftpConfigurationId: this.currentFtpProfile,
          requireDownload: this.currentRequiredDownload,
          requiredVersion: this.currentRequiredVersion,
        }
        SettingsDataService.updateDeviceConfigurations(data).then((response) => {
          if (response.data.isSuccess) {
            this.$toast.success('Server url updated successfully.', 'Success', {
              position: 'topRight',
            })
            this.getDataFromApi()
          }
        })
        this.dialogBulkDeviceUpdate = false
      },
      openDialogDeleteLocalData (item) {
        this.currentUserName = item.username
        this.dialogDeleteLocalData = true
      },
      deleteOrderProductsFromLocalDb () {
        this.$http
          .get('/Device/DeleteOrderProducts', {
            params: { username: this.currentUserName },
          })
          .then((response) => { })
          .catch(() => {
            this.$toast.error('Something went wrong.', 'Error', {
              position: 'topRight',
            })
          })
      },
      deleteFailedPickingProductsFromLocalDb () {
        this.$http
          .get('/Device/DeletePickingFailedProducts', {
            params: { username: this.currentUserName },
          })
          .then((response) => { })
          .catch(() => {
            this.$toast.error('Something went wrong.', 'Error', {
              position: 'topRight',
            })
          })
      },
      saveChanges () {
        // console.log("devices", this.selectedDevices);
        //  console.log("actions", this.workingActions);
        this.isLoading = true
        var data = new FormData()
        this.workingActions.forEach((item, index) => {
          if (item.id && typeof item.id !== undefined) {
            data.append(`deviceFiles[${index}].id`, item.id)
          }
          if (item.deviceFilePath && typeof item.deviceFilePath !== undefined) {
            data.append(
              `deviceFiles[${index}].deviceFilePath`,
              item.deviceFilePath,
            )
          }
          if (item.file) {
            data.append(`deviceFiles[${index}].file`, item.file)
          }
          if (item.action && item.action !== undefined) {
            data.append(`deviceFiles[${index}].action`, item.action)
          }
          if (item.version && typeof item.version !== undefined) {
            data.append(`deviceFiles[${index}].version`, item.version)
          }
          if (item.package && typeof item.package !== undefined) {
            data.append(`deviceFiles[${index}].package`, item.package)
          }
          if (item.icon) {
            const fileName = item.file.name.split('.').slice(0, -1).join('.')
            const imageName = fileName + '.jpg'
            data.append(
              `deviceFiles[${index}].image`,
              this.dataURLtoFile(item.icon, imageName),
            )
          }
        })

        this.selectedDevices.forEach((item, index) => {
          data.append(`devices[${index}]`, item.id)
        })

        this.$http
          .post('DeviceFile/UpdateFileActionListToDevices', data)
          .then((response) => {
            if (response.data.success) {
              this.$toast.success(response.data.message, 'Success', {
                position: 'topRight',
              })
            } else {
              this.$toast.error(response.data.message, 'Error', {
                position: 'topRight',
              })
            }
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      },
      generateReport () {
        this.$http.get('Device/GetDevicesReport').then((response) => {
          try {
            if (response.data && response.data.length > 0) {
              const fields = response.data[0].keys
              const opts = { fields }
              const csv = parse(response.data, opts)
              var fileUrl = window.URL.createObjectURL(new Blob([csv]))
              var fileLink = document.createElement('a')
              fileLink.href = fileUrl
              fileLink.setAttribute('download', 'MyDevices_Report.csv')
              document.body.appendChild(fileLink)
              fileLink.click()
            // console.log(csv);
            }
          } catch (err) {
            console.error(err)
          }
        })
      },
      dataURLtoFile (dataurl, filename) {
        var arr = dataurl.split(',')
        var mime = arr[0].match(/:(.*?);/)[1]
        var bstr = atob(arr[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n)

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }

        return new File([u8arr], filename, { type: mime })
      },
      getCompany (_company) {
        if (_company) {
          const names = _company.split('->')
          if (names.length > 1) {
            return names[names.length - 1]
          }
        }
        return _company
      },
      async getCompanyConfigurations (id) {
        try {
          const res = await this.$http(`/CompanyConfiguration?companyId=${id}`)
          if (res.data?.success) {
            return res.data.data || []
          }
        } catch (error) {

        }

        return []
      },
      chooseReference () {
        if (!this.referenceId || this.referenceId === 0) {
          return
        }

        this.getCompanyConfigurations(this.referenceId).then(data => {
          this.editedItems = data.map(x => ({
            name: x.name,
            type: x.type,
            value: x.value,
            description: x.description,
          }))
        })
      },
      addConfigurations () {

      },
      closeConfigurations () {
        this.dialogConfigurations = false
        this.editedItems = []
      },
      sendReboot (editedItem, { item }) {
        this.$http.get(`/Message/SendRebootCall?deviceId=${item.id}&seconds=${editedItem.seconds}`).then((response) => {
          if (response.data.success) {
            this.$toast.success('Command sent successfully.', 'Success', {
              position: 'topRight',
            })
          } else {
            this.$toast.error(response.data.message, 'Error', {
              position: 'topRight',
            })
          }
        })
      },
      sendXmlCommand (editedItem) {
        if (!this.selectedDevices.length) {
          this.$toast.error('No device selected', 'Error', {
            position: 'topRight',
          })
          return
        }

        this.selectedDevices.forEach(device => {
          this.$http.post(`/Message/SendXMLCommandTodevice?deviceId=${device.id}&info=${editedItem.info}`).then((response) => {
            if (response.data.success) {
              this.$toast.success(`Command sent successfully. Device:${device.name || device.id}`, 'Success', {
                position: 'topRight',
              })
            } else {
              this.$toast.error(response.data.message + ` Device:${device.name || device.id}`, 'Error', {
                position: 'topRight',
              })
            }
          })
        })
      },
      async addNewDevice (editedItem) {
        if (!this.selectedCompanyId) {
          this.$toast.error('Please select the company.', 'Error', { position: 'topRight' })
          return
        }

        if (editedItem.deviceId?.trim()?.length == 0) {
          this.$toast.error('Please enter the device id.', 'Error', { position: 'topRight' })
          return
        }

        const deviceRes = await this.$http.post('/Device', {
          image: '',
          deviceModel: '',
          macAddress: editedItem.deviceId,
          os: '',
          appVersion: '',
          username: '',
          ipAddress: '',
          zone: '',
          signalStrength: '',
          bssid: '',
          linkSpeed: '',
          essid: '',
          latitude: 0,
          longitude: 0,
          licenseNumber: '',
          installedApps: [],
          appInfoList: [],
        })

        if (!deviceRes.data.isSuccess) {
          this.$toast.error(deviceRes.data.message, 'Error', {
            position: 'topRight',
          })

          return
        }

        const res = await this.$http.post('/Company/AddDeviceToCompany', {
          companyId: this.selectedCompanyId,
          deviceId: editedItem.deviceId,
        })

        if (res.data.success) {
          this.$toast.success('Device created successfully.', 'Success', {
            position: 'topRight',
          })
        } else {
          this.$toast.error(deviceRes.data.message, 'Error', {
            position: 'topRight',
          })
        }
      },
      async updateTelnetServerDetails (editedItem, { item }) {
        const response = await this.$http.post(`/DeviceSecurityDetails/${item.id}/AddOrUpdate`, editedItem)
        if (!response.data.success) {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
          return
        }

        const tokenRes = await this.$http(`/DevicesQRAuthentication/generate?deviceId=${item.id}`)
        if (tokenRes.data.success) {
          item.telnet = response.data.data
          item.telnet.token = tokenRes.data.data.token

          this.$toast.success('Update successfully.', 'Success', {
            position: 'topRight',
          })
        } else {
          this.$toast.error(tokenRes.data.message, 'Error', {
            position: 'topRight',
          })
        }
      },
      async showTelnetServerDetails (item) {
        if (!item.telnet) {
          const res = await this.$http(`/DeviceSecurityDetails/${item.id}/GetDeviceSecurityDetail`)
          item.telnet = res.data?.data || {}
        }

        this.dialogTelnetServerDetails.show(item.telnet, { formTitle: 'Telnet Server Details', item })
      },
    },
  }
</script>
